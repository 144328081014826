<template>
  <v-container fluid>
    <v-expansion-panels dense small focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-container">
            <v-icon color="primary">mdi-filter</v-icon>
            <span>Filters</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="my-3">
          <v-row dense>
            <v-col cols="5">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="options.startDate"
                    label="Start Date"
                    readonly
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.startDate"
                  @input="updateRange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="5">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    v-model="options.endDate"
                    label="End Date"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="options.endDate"
                  @input="updateRange"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-autocomplete
                dense
                v-model="options.authorizerFrom"
                :items="users"
                label="Authorizer"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                dense
                v-model="options.templateFrom"
                :items="templates"
                label="Template"
                item-text="Name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                dense
                v-model="options.originatorFrom"
                :items="users"
                label="Originator"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense justify-end>
            <v-col cols="auto">
              <v-btn small color="grey" class="mt-3 justify-end" @click="clearFilters"
                >Clear Filters
                <v-icon>mdi-filter-remove</v-icon>
              </v-btn>

              <v-btn
                small
                class="mt-3 ml-5 justify-end"
                :loading="searchLoader"
                color="primary"
                @click="getFilteredStatus"
                >Apply Filters <v-icon>mdi-card-search</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          dense
          small
          :headers="headers"
          :items="Approvals"
          :search="search"
          show-select
          :items-per-page="options.itemsPerPage"
          :options.sync="options"
          class="elevation-1"
          :server-search="true"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon variant="primary" @click="editItem(item)">mdi-eye</v-icon>
          </template>
          <template v-slot:item.Status="{ item }">
            {{ getStatusName(item.Status) }}
          </template>
          <template v-slot:item.IsDraft="{ item }">
            {{ getDraftStatus(item.IsDraft) }}
          </template>
          <template v-slot:item.ObjType="{ item }">
            {{ getDocType(item.ObjType) }}
          </template>
          <template v-slot:item.Open="{ item }">
            <v-btn color="primary" text :to="`/sales/doc/13/${item.id}?isDoc=0`">
              <v-icon color="black">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  props: {
    selectedDocuments: Array,
  },
  components: {
    //ShowDialog,
  },
  data() {
    return {
      search: null,
      searchLoader: false,
      loader: false,
      loading: false,
      editedItem: {},
      selectedItems: [],
      documents: [],
      users: [],
      templates: [],
      unfilteredData: [],
      docTypeMap: {},
      dialog: false,
      selectedData: null,
      groupByEnabled: false,
      updateRange: null,
      options: {
        rowsPerPageOptions: [10, 20, 30],
        itemsPerPageOptions: [10, 20, 30],
        itemsPerPage: 50,
        page: 1,
        startDate: null,
        endDate: null,
        DocStatus: "O",
        search: null,
      },
      totalItems: 0,
      Approvals: [],
      headers: [
        { text: "#", value: "id" },
        { text: "Doc No.", value: "DocEntry" },
        { text: "Doc Type", value: "ObjType" },
        { text: "Originator", value: "user.name" },
        { text: "Template", value: "template.Name" },
        { text: "Status", value: "Status" },
        { text: "Remarks", value: "Remarks" },
        { text: "Is Draft.", value: "IsDraft" },
        { text: "Draft Key", value: "DraftEntry" },
        { text: "Date", value: "DocDate" },
        { text: "Open", value: "Open" },
      ],
      documentStatus: "Y",
    };
  },
  methods: {
    // defaultDates() {
    //   if (!this.options.startDate) {
    //     const today = new Date();
    //     this.options.startDate = today.toISOString().substr(0, 10);
    //   }

    //   if (!this.options.endDate) {
    //     const today = new Date();
    //     this.options.endDate = today.toISOString().substr(0, 10);
    //   }
    // },

    getApprovedApprovals() {
      console.log(this.selectedDocuments);
      if (this.selectedDocuments.length > 0) {
        const self = this;
        this.loading = true;
        this.$store
          .dispatch(
            "get",
            `/get_status_reports?selectedIds=${this.selectedDocuments.join(",")}&status=${
              this.documentStatus
            }`
          )
          .then((res) => {
            //console.log(res);
            self.Approvals = res.ResponseData;
            this.unfilteredData = self.Approvals;
            self.loading = false;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
      } else {
        // Show an error message if no documents are selected
        this.$refs.snackbar.show("Please select at least one document", "red");
      }
    },
    getFilteredStatus() {
      this.loading = true;
      const filteredApprovals = this.Approvals.filter((approval) => {
        return (
          (!this.options.startDate || approval.DocDate >= this.options.startDate) &&
          (!this.options.endDate || approval.DocDate <= this.options.endDate) &&
          (!this.options.authorizerFrom ||
            approval.UserSign === this.options.authorizerFrom) &&
          (!this.options.templateFrom ||
            approval.WtmCode === this.options.templateFrom) &&
          (!this.options.originatorFrom ||
            approval.OwnerID === this.options.originatorFrom)
        );
      });
      this.loading = false;
      //console.log(filteredApprovals);
      this.Approvals = filteredApprovals;
    },
    clearFilters() {
      if (this.unfilteredData.length === 0) {
        this.getApprovedApprovals();
        return;
      }
      //Reset the data
      this.loading = true;
      this.Approvals = this.unfilteredData;
      this.loading = false;
      //Clear Filter data
      this.options.startDate = null;
      this.options.endDate = null;
      this.options.authorizerFrom = null;
      this.options.templateFrom = null;
      this.options.originatorFrom = null;
    },
    getDocs() {
      const self = this;
      this.$store
        .dispatch("get", `/doc_model`)
        .then((res) => {
          res.forEach((doc) => {
            //console.log(doc.ObjectID.toString(), doc.DocumentName);
            self.$set(self.docTypeMap, doc.ObjectID.toString(), doc.DocumentName);
          });
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUsers() {
      const self = this;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.users = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTemplates() {
      const self = this;
      this.$store
        .dispatch("get", `/approval_templates`)
        .then((res) => {
          self.templates = res.ResponseData;
          console.log(self.templates);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },

  computed: {
    getStatusName() {
      return function (status) {
        const statusMap = {
          //A=Generated by Authorizer, C=Canceled, N=Rejected, P=Generated, W=Pending, Y=Approved
          A: "Generated by Authorizer",
          C: "Canceled",
          N: "Rejected",
          P: "Generated",
          W: "Pending",
          Y: "Approved",
        };
        // Return the full name if it exists in the mapping, otherwise return the original status
        return statusMap[status] || status;
      };
    },
    getDraftStatus() {
      return function (isDraft) {
        const draftStatusMap = {
          N: "No",
          Y: "Yes",
        };
        return draftStatusMap[isDraft] || isDraft;
      };
    },
    getDocType() {
      return function (docType) {
        return this.docTypeMap[docType] || docType;
      };
    },
  },

  created() {
    //this.defaultDates();
    this.getApprovedApprovals();
    this.getDocs();
    this.getUsers();
    this.getTemplates();
  },
};
</script>
